import styled from 'styled-components'
import { getRem } from 'styles/utils'

import { lighten } from 'polished'

export const RowStyled = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  top: 0;
  height: fit-content;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 12px 16px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.selected : ''};

  &:hover {
    background-color: ${({ theme }) => lighten(0.23, theme.colors.selected)};
  }

  .text--block {
    display: flex;
    flex-direction: column;
    .title {
      font-size: ${getRem(16)};
      margin-bottom: ${getRem(10)};
      font-weight: 400;
      text-transform: capitalize;
    }
    .subtitle {
      font-size: ${getRem(10)};
      color: ${({ theme }) => theme.colors.gray50};
    }
  }
`
