import styled from 'styled-components'
import { getRem } from 'styles/utils'
import { MemoriaIcon, PropuestaIcon } from 'assets/icons'

export const CollectionSelectorStyled = styled.div`
  max-height: 36vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  overflow-y: scroll;
  padding-top: ${({ theme }) => theme.spacing.spacingXS};
  padding-bottom: ${({ theme }) => theme.spacing.spacingXS};
`

export const DistrictSelectorStyled = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
  padding-bottom: 12px;
  margin-bottom: 12px;
`

const MenuItemStyled = styled.div`
  cursor: pointer;
  padding: 6px 14px 4px;
  border-radius: 4px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.orange : 'transparent'};
  width: 100%;
  display: flex;
  align-items: center;
  font-size: ${getRem(13)};
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
    color: ${({ theme }) => theme.colors.white};
  }
`

export const CollectionMenuButton = styled(MenuItemStyled)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-transform: capitalize;
  width: fit-content;
  margin-right: ${getRem(4)};
  height: 100%;
  min-width: 68px;
  letter-spacing: -0.5px;
  padding: 4px 2px 4px 6px;
  background-color: ${({ colorConfig, selected, theme }) =>
    selected ? theme.colors.orange : colorConfig?.background || theme.colors.white};
  color: ${({ colorConfig, selected, theme }) =>
    selected ? theme.colors.white : colorConfig?.text || theme.colors.textColor};
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 8px;
  }
`

export const CategoryMenuButton = styled(MenuItemStyled)`
  display: flex;
  width: auto;
  background-color: ${({ colorConfig, selected, theme }) =>
    selected ? theme.colors.orange : colorConfig?.background || theme.colors.white};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.brand2};
  padding-left: 6px;
  padding-right: 6px;
`

export const DistrictMenuButton = styled(CollectionMenuButton)`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.brand2};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.orange : theme.colors.gray10};
  padding: 8px 16px;
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: 0.1px;
  &:not(:last-child) {
    margin-right: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
`

export const MemoriaIconStyled = styled(MemoriaIcon)`
  margin-right: ${getRem(6)};
`

export const PropuestaIconStyled = styled(PropuestaIcon)`
  margin-right: ${getRem(6)};
`

export const CollectionActionsStyled = styled.article`
  display: flex;
  height: 52px;
  align-items: flex-start;
  margin-bottom: 16px;
`

export const CategoriesGroupStyled = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
`
