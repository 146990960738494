// This formater works properly with fake data, please cheack with strapi data
export const formatCollections = (collections) => {
  const cols = collections.reduce((acc, curr) => {
    return { ...acc, [curr.name]: curr }
  }, {})
  return cols
}

export const camelize = (str) =>
  str
    .toLowerCase()
    .replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9]+(.)/g, (unused, chr) => chr.toUpperCase())

export const filterByName = (list, term) => {
  return list.filter(({ properties }) => {
    const name = properties.name.toString()
    return name.toLowerCase().includes(term.toLowerCase())
  })
}

const filterCollectionbyCategory = (features, currentCategory) => {
  return features.filter((feature) => currentCategory === feature.properties.category)
}

export const filterCategories = (collectionList, currentCategory) => {
  if (!collectionList) return null
  const clonedCollections = JSON.parse(JSON.stringify(collectionList))

  Object.keys(clonedCollections).forEach((collection) => {
    const { features } = clonedCollections[collection]

    clonedCollections[collection].features = filterCollectionbyCategory(
      features,
      currentCategory,
    )
  })

  return clonedCollections
}

export const mapCategoryList = (rawCollections) => {
  const mappedCategoriesObject = {}

  rawCollections.forEach(({ name, categories }) => {
    mappedCategoriesObject[name] = {}

    categories.forEach(({ name: catName, slug }) => {
      mappedCategoriesObject[name][camelize(slug)] = catName
    })
  })

  return mappedCategoriesObject
}
