import { TAG_PROPERTIES } from './Tags.constants'

const filterValidProperties = (properties) => {
  return Object.keys(properties).filter((property) => {
    return properties[property] === '1' && property !== 'id' && TAG_PROPERTIES[property]
  })
}

export const getCategorizedProperties = (properties) => {
  const filteredProperties = filterValidProperties(properties)

  return filteredProperties.reduce((acc, property) => {
    const section = property.split('_')[0].toLocaleLowerCase()

    if (acc[section]) {
      return { ...acc, [section]: [...acc[section], property] }
    }
    return { ...acc, [section]: [property] }
  }, {})
}
