import React from 'react'
import PropTypes from 'prop-types'

import { LoaderStyled } from './Loader.styled'

const Loader = ({ show }) => {
  return (
    <LoaderStyled show={show}>
      <div className="la-ball-triangle-path la-2x la-dark">
        <div />
        <div />
        <div />
      </div>
    </LoaderStyled>
  )
}

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Loader
