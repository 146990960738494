import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getCategoryComponent, getCollectionIcon } from 'utils'
import { selectCategoriesConfig } from 'state/Collections/selectors'

import { AvatarStyled } from './Avatar.styled'

const Avatar = ({ category, collection }) => {
  const categoriesConfig = useSelector(selectCategoriesConfig)

  const {
    accentColor: { color },
  } = categoriesConfig.find(({ name }) => name.toLowerCase() === category.toLowerCase())

  return (
    <AvatarStyled color={color}>
      {getCollectionIcon({ collection })}
      <div className="category-icon">
        {getCategoryComponent({ category, categoriesConfig })}
      </div>
    </AvatarStyled>
  )
}

Avatar.propTypes = {
  category: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
}

export default Avatar
