import { createGlobalStyle } from 'styled-components'
import theme from 'styles/theme'

export default createGlobalStyle`
  * {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }

  div, section {
    box-sizing: border-box;
  }

  :root {
    font-size: 16px;
  }

  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${theme.colors.white};
  }

  input:focus,
  textarea:focus {
    outline: none !important;
  }
  button:focus{
    outline: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  body {
    text-transform: initial;
  }

  .hoverOpacity:hover {
    opacity: 0.8;
  }

  input {
    border: 1px solid black;
    border-radius: 3px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }

  .font-weight-light {
    font-weight: 300;
  }
`
