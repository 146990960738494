import styled from 'styled-components'

import { TextSubtitle } from 'styles/text'
import { getRem } from 'styles/utils'

export const ContainerStyled = styled.div`
  margin-bottom: 5px;
`

export const SectionTitleStyled = styled(TextSubtitle)`
  font-weight: 500;
  text-transform: Capitalize;
  margin-bottom: 5px;
`

export const TagContainerStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
  
`

export const TagStyled = styled.li`
  background-color: rgba(255, 106, 19, 0.1);
  padding: 5px 6px 3px;;
  border-radius: 3px;
  font-size: ${getRem(10)};
  width: fit-content;
  margin-bottom: 5px;
  white-space: nowrap;
`
