import React from 'react'
import PropTypes from 'prop-types'

import { Paragraph } from 'styles/text'
import { InfoItemStyled } from '../Card/Card.styled'

const FeatureInfoDetail = ({ icon, content }) => {
  if (!content) {
    return null
  }

  return (
    <>
      <InfoItemStyled>
        {icon}
        <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
      </InfoItemStyled>
    </>
  )
}

FeatureInfoDetail.defaultProps = {
  icon: null,
  content: null,
}

FeatureInfoDetail.propTypes = {
  icon: PropTypes.object,
  content: PropTypes.string,
}

export default FeatureInfoDetail
