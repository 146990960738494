import { useCallback, useEffect } from 'react'

const useKeydown = ({ key, callback }) => {
  const handleKey = useCallback(
    ({ keyCode }) => {
      keyCode === key && callback()
    },
    [callback],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKey, false)

    return () => {
      document.removeEventListener('keydown', handleKey, false)
    }
  }, [])
}

export default useKeydown
