import styled from 'styled-components'

import isMobile from 'utils/isMobile'
import { getRem } from 'styles/utils'
import { TextH4 } from 'styles/text'
import theme from 'styles/theme'

export const ActivityText = styled.span`
  color: ${theme.colors.gray70};
  font-size: ${getRem(10)};
`

export const CardStyled = styled.section`
  background: ${theme.colors.white};
  background-image: ${({ color }) =>
    `linear-gradient(to bottom, ${theme.colors[color]}, #ffffff 22%)`};
  border-left: 1px solid ${theme.colors.gray20};
  position: ${isMobile ? 'fixed' : 'abosolute'};
  top: ${isMobile ? '4.75rem' : theme.components.CardYPos};
  left: ${({ clickedFeature, panelIsVisible }) => {
    if (isMobile) return '12px'
    return clickedFeature && panelIsVisible ? theme.components.panelWidth : 0
  }};
  overflow: scroll;
  transition: left 0.3s ease-out;
  transition-delay: 0.4s;
  word-break: break-word;
  width: ${isMobile ? 'calc(100vw - 24px)' : theme.components.panelWidth};
  height: auto;
  max-height: 100vh;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: ${theme.components.panelShadow};
  p {
    & > svg {
      cursor: pointer;
      color: ${theme.colors.white};
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
`

export const CardHeaderStyled = styled.header`
  display: flex;
  align-items: center;
  padding-top: ${getRem(18)};
  p {
    text-transform: capitalize;
  }
`

export const CardTextH4 = styled(TextH4)`
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1rem;
`

export const LineStyled = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => theme.colors[color] || theme.colors.gray80};
  margin: ${getRem(14)} 0;
  opacity: 0.6;
`

export const InfoItemStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    margin-right: 6px;
    flex-shrink: 0;
    align-self: flex-start;
  }

  p {
    font-size: ${getRem(12)};
    padding-left: ${getRem(18)};
  }
`

export const CardBoxedContentStyled = styled.div`
  padding: 0 ${getRem(8)};
  margin: 16px 0 18px;
`

export const DescriptionStyled = styled.div`
  margin-bottom: ${getRem(14)};
`

export const CardContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: inset 4px 0px 9px -8px rgba(0, 0, 0, 0.75);
  padding: ${getRem(16)} ${getRem(24)} ${getRem(32)};
`
