import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const CircleFormStyled = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ color, theme }) => theme.colors[color]};
  margin-right: ${getRem(6)};
  border-radius: 50%;
  border: ${({ outlined, borderColor }) => outlined && `1px solid ${borderColor}`};
`

export const MainContainerStyled = styled.main`
  display: flex;
`
