export const TAG_PROPERTIES = {
  knowledge_artistic: 'Artístico',
  knowledge_traditional: 'Tradicional',
  knowledge_innovative: 'Innovación',
  knowledge_technical: 'Técnico',
  material_textile: 'Textil',
  material_industry: 'Industrial',
  material_electronic: 'Electrónico',
  material_wood: 'Madera',
  material_paper: 'Papel',
  material_paint: 'Pintura',
  material_others: 'Otros',
  material_waste: 'Desperdicios',
  tools_bigformat: 'Gran formato',
  tools_smallformat: 'Pequeño formato',
  outcome_assembly: 'Montaje',
  outcome_product: 'Producción',
  material_metal: 'Metal',
  pi_storage: 'Almacenaje',
  pi_commercial: 'Comercial',
}

export const TAG_SECTIONS = {
  knowledge: 'conocimiento',
  material: 'materiales',
  tools: 'herramientas',
  outcome: 'servicios',
  pi: 'tipología',
}
