export const COLLECTION_GET_ALL_COLLECTIONS = 'COLLECTION_GET_ALL_COLLECTIONS'
export const COLLECTION_GET_ALL_FAKE_COLLECTIONS = 'COLLECTION_GET_ALL_FAKE_COLLECTIONS'
export const COLLECTION_SET_ALL_COLLECTIONS = 'COLLECTION_SET_ALL_COLLECTIONS'
export const COLLECTION_SET_COLLECTIONS_ERROR = 'COLLECTION_SET_COLLECTIONS_ERROR'
export const COLLECTION_SET_COLLECTIONS_LOADING = 'COLLECTION_SET_COLLECTIONS_LOADING'
export const COLLECTION_SET_COLLECTIONS_NAMES = 'COLLECTION_SET_COLLECTIONS_NAMES'
export const COLLECTION_SET_COLLECTIONS_SEARCH_TERM =
  'COLLECTION_SET_COLLECTIONS_SEARCH_TERM'
export const COLLECTION_SET_MAP_CENTER_COORDINATES =
  'COLLECTION_SET_MAP_CENTER_COORDINATES'
export const COLLECTION_SET_POPUP_CONTENT = 'COLLECTION_SET_POPUP_CONTENT'
export const COLLECTION_SET_MODAL_CONTENT = 'COLLECTION_SET_MODAL_CONTENT'
export const COLLECTION_SET_CLICKED_FEATURE = 'COLLECTION_SET_CLICKED_FEATURE'
export const COLLECTION_SET_CURRENT_COLLECTION_AND_CATEGORY =
  'COLLECTION_SET_CURRENT_COLLECTION_AND_CATEGORY'
export const COLLECTION_SET_TOTAL_FEATURES = 'COLLECTION_SET_TOTAL_FEATURES'
export const COLLECTION_SET_CATEGORIES = 'COLLECTION_SET_CATEGORIES'
export const COLLECTION_SET_CATEGORIES_CONFIG = 'COLLECTION_SET_CATEGORIES_CONFIG'
export const COLLECTION_SET_DISTRICTS = 'COLLECTION_SET_DISTRICTS'
export const COLLECTION_SET_DISTRICT = 'COLLECTION_SET_DISTRICT'
export const COLLECTION_GET_DISTRICT = 'COLLECTION_GET_DISTRICT'
