import styled from 'styled-components'

export const PanelContainerStyled = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.components.panelTopPos};
  left: ${({ panelIsVisible, theme: { components } }) => {
    return panelIsVisible ? components.panelLeftPos : `-${components.panelWidth}`
  }};
  z-index: 9999;
  transition: left 0.3s ease-out;
  display: flex;
  box-shadow: ${({ panelIsVisible, theme }) =>
    panelIsVisible ? theme.components.panelShadow : ''};
  max-width: calc(100vw - 3rem);
  height: 100%;
  max-height: calc(100vh - 1.5rem);
`

export const PanelInnerStyled = styled.section`
  width: ${({ theme }) => theme.components.panelWidth};
  min-height: ${({ theme, listIsVisible }) =>
    listIsVisible ? theme.components.panelHeight : 'auto'};
  max-height: ${({ theme }) => theme.components.panelHeight};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 6px;
  overflow: hidden;
`
