import React from 'react'
import { useSelector } from 'react-redux'

import { capitalize, getCategoryAccentColor } from 'utils'
import {
  selectCurrentCollection,
  selectCurrentCategory,
  selectCategoriesConfig,
  selectCurrentDistrict,
} from 'state/Collections/selectors'

import {
  BreadCrumbStyled,
  CategoryStyled,
  SeparatorStyled,
} from './CardBreadCrumb.styled'

const CardBreadCrumb = () => {
  const district = useSelector(selectCurrentDistrict)
  const collection = useSelector(selectCurrentCollection)
  const category = useSelector(selectCurrentCategory)
  const categoriesConfig = useSelector(selectCategoriesConfig)
  
  const accentColor = getCategoryAccentColor(category, categoriesConfig)

  return (
    <BreadCrumbStyled>
      {capitalize(district.name)}
      <SeparatorStyled>{' / '}</SeparatorStyled>
      {capitalize(collection)}
      <SeparatorStyled>{' / '}</SeparatorStyled>
      <CategoryStyled color={accentColor}>{category}</CategoryStyled>
    </BreadCrumbStyled>
  )
}

export default CardBreadCrumb
