/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ImageStyled, ButtonFocusableStyled } from './Image.styled'

const Image = ({ alt, fallback, src, setImageVisibility, onClick }) => {
  const [loaded, setLoaded] = useState(false)

  const handleError = (event) => {
    if (fallback) {
      event.target.src = fallback
      event.target.onError = null
      return
    }
    setImageVisibility(false)
  }

  const handleLoad = () => {
    setLoaded(true)
  }

  return (
    <ImageStyled loaded={loaded}>
      <ButtonFocusableStyled onClick={onClick} type="button">
        <img src={src} alt={alt} onError={handleError} onLoad={handleLoad} />
      </ButtonFocusableStyled>
    </ImageStyled>
  )
}

Image.defaultProps = {
  fallback: null,
  setImageVisibility: () => null,
  onClick: () => null,
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  src: PropTypes.string.isRequired,
  setImageVisibility: PropTypes.func,
  onClick: PropTypes.func,
}

export default Image
