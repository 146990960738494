import axios from 'axios'
import logError from './logger'

export const getAll = () => {
  return axios
    .get('/sources/config/index.json')
    .then(({ data }) => {
      return data['feature-collections']
    })
    .catch('ERROR GETALL AXIOS CALL', logError)
}

export const getCollection = (districtName, collectionName) => {
  return axios
    .get(`/sources/districts/${districtName}/${collectionName}.geojson`)
    .then(({ data }) => data)
    .catch('ERROR GETCOLLECTION AXIOS CALL', logError)
}

export const getCategoriesConfig = () => {
  return axios
    .get('/sources/config/index.json')
    .then(({ data }) => data.categories)
    .catch('ERROR GETCATEGORIES AXIOS CALL', logError)
}

export const getDistricts = () => {
  return axios
    .get('/sources/config/index.json')
    .then(({ data }) => data.districts)
    .catch('ERROR GETCATEGORIES AXIOS CALL', logError)
}
