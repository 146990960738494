import * as ActionTypes from './types'

export const getAllCollections = () => ({
  type: ActionTypes.COLLECTION_GET_ALL_COLLECTIONS,
})

export const getDistrict = (district) => ({
  type: ActionTypes.COLLECTION_GET_DISTRICT,
  payload: district,
})

export const setAllCollections = (payload) => {
  return {
    type: ActionTypes.COLLECTION_SET_ALL_COLLECTIONS,
    payload,
  }
}

export const setCollectionsLoading = (payload) => ({
  type: ActionTypes.COLLECTION_SET_COLLECTIONS_LOADING,
  payload,
})

export const setCollectionsError = (payload) => ({
  type: ActionTypes.COLLECTION_SET_COLLECTIONS_ERROR,
  payload,
})

export const setCollectionsNames = (payload) => ({
  type: ActionTypes.COLLECTION_SET_COLLECTIONS_NAMES,
  payload,
})

export const setCollectionsSearchTerm = (payload) => ({
  type: ActionTypes.COLLECTION_SET_COLLECTIONS_SEARCH_TERM,
  payload,
})

export const setClickedFeature = (payload) => ({
  type: ActionTypes.COLLECTION_SET_CLICKED_FEATURE,
  payload,
})

export const setCategories = (payload) => ({
  type: ActionTypes.COLLECTION_SET_CATEGORIES,
  payload,
})

export const setCategoriesConfig = (payload) => ({
  type: ActionTypes.COLLECTION_SET_CATEGORIES_CONFIG,
  payload,
})

export const setCurrentCollectionAndCategory = (payload) => ({
  type: ActionTypes.COLLECTION_SET_CURRENT_COLLECTION_AND_CATEGORY,
  payload,
})

export const setTotalFeatures = (payload) => ({
  type: ActionTypes.COLLECTION_SET_TOTAL_FEATURES,
  payload,
})

export const setDistricts = (payload) => ({
  type: ActionTypes.COLLECTION_SET_DISTRICTS,
  payload,
})

export const setDistrict = (payload) => ({
  type: ActionTypes.COLLECTION_SET_DISTRICT,
  payload,
})
