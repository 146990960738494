import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FlyToInterpolator } from 'react-map-gl'
import * as d3 from 'd3-ease'

import { selectCollections } from 'state/Collections/selectors'
import { ZOOM_OUT } from 'constants/index'

import { getFeaturesBounds, getMapViewport } from './MapboxMap.helpers'

export const useBoundedViewport = ({ position }) => {
  const initialViewport = {
    latitude: position[0],
    longitude: position[1],
    zoom: ZOOM_OUT,
    bearing: 0,
    pitch: 0,
    transitionDuration: 800,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: d3.easeCubic,
  }

  const [viewport, setViewport] = useState(initialViewport)

  const collections = useSelector(selectCollections)
  const mapBounds = getFeaturesBounds(collections)
  const computedViewport = getMapViewport(mapBounds)

  const boundsSumatory = Object.values(mapBounds || {}).reduce(
    (acc, current) => acc + current.lat + current.lng,
    0,
  )

  const resetViewport = () => {
    setViewport({
      ...viewport,
      ...computedViewport,
    })
  }

  useEffect(() => {
    setViewport({
      ...viewport,
      ...computedViewport,
    })
  }, [boundsSumatory])

  return {
    resetViewport,
    viewport,
    setViewport,
  }
}
