import styled from 'styled-components'
import { getRem } from 'styles/utils'
import theme from 'styles/theme'

export const BreadCrumbStyled = styled.div`
  margin-top: ${getRem(8)};
  font-size: ${getRem(10)};
`

export const SeparatorStyled = styled.span`
  color: ${theme.colors.grays30};
`

export const CategoryStyled = styled.span`
  color: ${({ color }) => theme.colors[color]};
`
