import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import isMobile from 'utils/isMobile'
import { setMobileCardVisibility, setPanelVisibility } from 'state/Ui/actions'
import { selectPanelIsVisible } from 'state/Ui/selectors'
import { SideArrow, SimplifiedLogo } from 'assets/icons'

import { PanelHandlerStyled } from './PanelHandler.styled'

const PanelHandler = () => {
  const dispatch = useDispatch()
  const panelIsVisible = useSelector(selectPanelIsVisible)

  const handlePanel = () => {
    dispatch(setPanelVisibility(!panelIsVisible))
    isMobile && dispatch(setMobileCardVisibility(false))
  }

  useEffect(() => {
    return () => {
      dispatch(setPanelVisibility(window.innerWidth > 768))
    }
  }, [window.innerWidth])

  return (
    <PanelHandlerStyled
      onClick={handlePanel}
      panelIsVisible={panelIsVisible}
      className={`${!panelIsVisible ? 'is-hidden' : ''}`}
    >
      <div className="inner">
        {!panelIsVisible && (
          <figure>
            <SimplifiedLogo />
          </figure>
        )}
        <span>
          <SideArrow />
        </span>
      </div>
    </PanelHandlerStyled>
  )
}

export default PanelHandler
