import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useKeydown } from 'hooks'

import { CrossIcon } from 'assets/icons'

import ImageLightboxError from './components/ImageLightboxError.component'

import {
  ImageLightBoxStyled,
  ImageLightBoxCloseButtonStyled,
  ImageLightBoxInnerStyled,
} from './ImageLightBox.styled'

const ImageLightBox = ({ imageSrc, imageAlt, onClose }) => {
  const [error, setError] = useState(null)
  const lightBoxImageSrc = `${imageSrc.split('.jpg')[0]}-lb.jpg`

  useKeydown({ key: 27, callback: onClose })

  const handleCloseClick = () => {
    onClose()
    setError(null)
  }

  const handleOverlayClick = ({ target }) => {
    if (target.id !== 'lightbox-overlay') {
      return
    }

    onClose()
    setError(null)
  }

  const handleError = (imageError) => {
    setError(imageError)
  }

  return (
    <>
      <ImageLightBoxStyled id="lightbox-overlay" onClick={handleOverlayClick}>
        <ImageLightBoxInnerStyled hasError={error}>
          <ImageLightBoxCloseButtonStyled type="button" onClick={handleCloseClick}>
            <CrossIcon />
          </ImageLightBoxCloseButtonStyled>
          {!error && (
            <figure>
              <img src={lightBoxImageSrc} alt={imageAlt} onError={handleError} />
            </figure>
          )}
          {error && <ImageLightboxError onClick={handleCloseClick} />}
        </ImageLightBoxInnerStyled>
      </ImageLightBoxStyled>
    </>
  )
}

ImageLightBox.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ImageLightBox
