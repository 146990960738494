import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { setCardContent } from 'state/Info/actions'
import { setClickedFeature } from 'state/Collections/actions'
import { selectProjectInfo } from 'state/Info/selectors'

import { getKey } from 'utils/key-generator'

import { NavigationStyled, ListBeforeStyled } from './Navigation.styled'

// FYI: The client request to hide this items temporaly
const HIDDEN_ITTEMS = ['team', 'contact']

const Navigation = ({ nav }) => {
  const dispatch = useDispatch()
  const projectInfo = useSelector(selectProjectInfo)

  const handleClick = ({ url }) => {
    dispatch(setCardContent(projectInfo[url]))
    dispatch(setClickedFeature(null))
  }

  return (
    <NavigationStyled>
      <ul>
        {nav.map(
          (item) =>
            !HIDDEN_ITTEMS.includes(item.url) && (
              <li role="presentation" onClick={() => handleClick(item)} key={getKey()}>
                <ListBeforeStyled />
                <span>{item.label}</span>
              </li>
            ),
        )}
      </ul>
    </NavigationStyled>
  )
}

Navigation.propTypes = {
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Navigation
