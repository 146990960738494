import styled from 'styled-components'

import { getRem } from 'styles/utils'
import theme from 'styles/theme'

export const ImageStyled = styled.figure`
  height: ${({ loaded }) => (loaded ? 'auto' : 0)};
  overflow: hidden;
  margin-bottom: ${({ loaded }) => (loaded ? getRem(9) : 0)};

  img {
    max-width: 100%;
    min-width: 100%;
  }
`
export const ButtonFocusableStyled = styled.button`
  cursor: pointer;
  &:focus {
    outline: 1px solid ${theme.colors.naviBlue};
  }
`
