import * as CollectionApi from 'api/collections-api'
import { put, call, all, select } from 'redux-saga/effects'
import * as types from './types'
import * as actions from './actions'
import * as selectors from './selectors'

import { formatCollections, mapCategoryList } from './helpers'

function* getCollectionsSaga() {
  try {
    yield put(actions.setCollectionsLoading(true))
    const districts = yield call(CollectionApi.getDistricts)
    yield put(actions.setDistricts({ districts }))
    const rawCollections = yield call(CollectionApi.getAll)
    const slugs = []
    const { id } = yield select(selectors.selectCurrentDistrict)
    const response = yield all(
      rawCollections.map(({ slug }) => {
        slugs.push(slug)
        return call(CollectionApi.getCollection, id, slug)
      }),
    )
    const categoryListSelector = mapCategoryList(rawCollections)
    yield put(actions.setCategories(categoryListSelector))

    yield put(actions.setCollectionsNames(slugs))

    const collections = formatCollections(response)
    yield put(actions.setAllCollections({ collections }))

    const categoriesConfig = yield call(CollectionApi.getCategoriesConfig)
    yield put(actions.setCategoriesConfig(categoriesConfig))
  } catch (error) {
    yield put(actions.setCollectionsError(true))
  } finally {
    yield put(actions.setCollectionsLoading(false))
  }
}

function* getDistrictSaga({ payload }) {
  yield put(actions.setDistrict({ district: payload }))
  yield put(
    actions.setCurrentCollectionAndCategory({ collection: 'all', category: null }),
  )
  yield put(actions.getAllCollections())
}

export default {
  [types.COLLECTION_GET_ALL_COLLECTIONS]: getCollectionsSaga,
  [types.COLLECTION_GET_DISTRICT]: getDistrictSaga,
}
