/* eslint-disable array-callback-return */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { TAG_PROPERTIES, TAG_SECTIONS } from './Tags.constants'
import { getCategorizedProperties } from './Tags.bussiness'

import { LineStyled } from '../Card/Card.styled'
import {
  ContainerStyled,
  SectionTitleStyled,
  TagContainerStyled,
  TagStyled,
} from './Tags.styled'

const Tags = ({ properties }) => {
  const tagsSections = useMemo(() => getCategorizedProperties(properties), [
    JSON.stringify(properties),
  ])

  if (!Object.keys(tagsSections).length) return null

  return (
    <>
      <LineStyled />
      {Object.keys(tagsSections).map((section) => (
        <ContainerStyled key={section}>
          <SectionTitleStyled>{TAG_SECTIONS[section]} </SectionTitleStyled>
          <TagContainerStyled>
            {tagsSections[section].map((tag) => (
              <TagStyled key={tag}>{TAG_PROPERTIES[tag]}</TagStyled>
            ))}
          </TagContainerStyled>
        </ContainerStyled>
      ))}
    </>
  )
}

Tags.propTypes = {
  properties: PropTypes.instanceOf(Object).isRequired,
}

export default Tags
