import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { setClickedFeature } from 'state/Collections/actions'
import { selectMobileCardIsVisible, selectPanelIsVisible } from 'state/Ui/selectors'
import { setMobileCardVisibility } from 'state/Ui/actions'
import {
  selectCurrentCollection,
  selectCategoriesConfig,
  selectCurrentDistrict,
} from 'state/Collections/selectors'
import { useKeydown } from 'hooks'
import { Avatar, Image, VimeoPlayer, ImageLightBox } from 'components/common'
import {
  CrossIcon,
  LocatorIcon,
  PhoneIcon,
  WebsiteIcon,
  MailIcon,
  FacebookIcon,
  InstagramOutlinedIcon,
} from 'assets/icons'
import { getCategoryAccentColor } from 'utils/index'
import isMobile from 'utils/isMobile'
import { getKey } from 'utils/key-generator'

import { Paragraph, TextRight } from 'styles/text'
import { CardBreadCrumb, Tags, FeatureInfoDetail } from '..'

import {
  ActivityText,
  CardBoxedContentStyled,
  CardContainerStyled,
  CardHeaderStyled,
  CardStyled,
  CardTextH4,
  DescriptionStyled,
  LineStyled,
} from './Card.styled'

const getImageSrc = ({ name, districtName, collection, id }) => {
  const formatedName = name
    .normalize('NFD')
    .replace(/[\u0300-\u036f.,_*]/g, '')
    .replace(/\s/g, '-')
    .toLowerCase()
  const baseImage = `${process.env.REACT_APP_CDN_URL}/images`

  return `${baseImage}/${districtName}-${collection}-${id}-${formatedName}.jpg`.toLowerCase()
}

const Card = ({ clickedFeature, resetViewport }) => {
  const panelIsVisible = useSelector(selectPanelIsVisible)
  const dispatch = useDispatch()
  const collection = useSelector(selectCurrentCollection)
  const { name: districtName } = useSelector(selectCurrentDistrict)
  const categoriesConfig = useSelector(selectCategoriesConfig)
  const isMobileCardIsVisible = useSelector(selectMobileCardIsVisible)

  const [showImage, setShowImage] = useState(true)
  const [showImageLightBox, setShowImageLightBox] = useState(false)

  const { properties } = clickedFeature

  useEffect(() => {
    setShowImage(true)
  }, [properties])

  const {
    id,
    name,
    category,
    description,
    district,
    address,
    neighborhood,
    neighbour,
    activity,
    vimeo_video_id: vimeoId,
    con_telf: phone,
    con_mail: email,
    con_web: website,
    con_fb: facebook,
    con_insta: instagram,
  } = properties

  const infoDetailsProperties = [
    {
      content: `${address},<br/>${neighborhood || neighbour}, ${district}`,
      icon: <LocatorIcon />,
    },
    { content: phone, icon: <PhoneIcon /> },
    { content: website, icon: <WebsiteIcon /> },
    { content: email, icon: <MailIcon /> },
    { content: facebook, icon: <FacebookIcon /> },
    { content: instagram, icon: <InstagramOutlinedIcon /> },
  ]

  const onCloseCard = useCallback(() => {
    isMobile && dispatch(setMobileCardVisibility(false))
    dispatch(setClickedFeature(null))
    resetViewport()
  }, [resetViewport])

  useKeydown({ key: 27, callback: onCloseCard })

  const handleImageLightBox = () => {
    setShowImageLightBox(true)
  }

  const color = getCategoryAccentColor(category, categoriesConfig)

  if (isMobile && !isMobileCardIsVisible) return null

  return (
    <CardStyled {...{ clickedFeature, panelIsVisible, color }} aria-label="open">
      <CardContainerStyled>
        <TextRight>
          <CrossIcon onClick={onCloseCard} />
        </TextRight>
        <CardHeaderStyled>
          <Avatar {...{ collection, category }} />
          <div>
            <CardTextH4>{name.toLowerCase()}</CardTextH4>
            <ActivityText>{activity}</ActivityText>
            <CardBreadCrumb />
          </div>
        </CardHeaderStyled>
        <LineStyled {...{ color }} />
        <DescriptionStyled>
          {description && <Paragraph>{description}</Paragraph>}
        </DescriptionStyled>
        {showImage && (
          <>
            <Image
              src={getImageSrc({ name, districtName, collection, id })}
              setImageVisibility={setShowImage}
              alt={name}
              onClick={handleImageLightBox}
            />

            {showImageLightBox && (
              <ImageLightBox
                imageAlt={name}
                imageSrc={getImageSrc({ name, districtName, collection, id })}
                onClose={() => setShowImageLightBox(false)}
              />
            )}
          </>
        )}
        {vimeoId && <VimeoPlayer videoId={vimeoId} />}
        <CardBoxedContentStyled>
          {infoDetailsProperties.map(({ content, icon }) => {
            return (
              <FeatureInfoDetail icon={icon} content={content} key={content + getKey()} />
            )
          })}
        </CardBoxedContentStyled>
        <Tags properties={properties} />
      </CardContainerStyled>
    </CardStyled>
  )
}

Card.propTypes = {
  resetViewport: PropTypes.func.isRequired,
  clickedFeature: PropTypes.instanceOf(Object).isRequired,
}

export default Card
