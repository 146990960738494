/* eslint-disable no-param-reassign */
import { createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash.isequal'
import { filterByName, filterCategories } from './helpers'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const selectDistricts = (state) => state.collections.districts

export const selectCurrentDistrict = (state) => state.collections.currentDistrict

export const selectCurrentCollection = (state) => state.collections.currentCollection

export const selectSearchTerm = (state) => state.collections.searchTerm

export const selectCollectionsNames = (state) => state.collections.collectionsNames

export const selectClickedFeature = (state) => state.collections.clickedFeature

export const selectCurrentCategory = (state) => state.collections.currentCategory

export const selectTotalFeatures = (state) => state.collections.totalFeatures

export const selectCategories = (state) => state.collections.categories

export const selectCategoriesConfig = (state) => state.collections.categoriesConfig

const selectCollectionList = (state) => state.collections.collectionsList

export const selectCollectionsList = createDeepEqualSelector(
  [selectCollectionList, selectCurrentCategory, selectCurrentCollection],
  (collectionsList, currentCategory, currCollection) => {
    if (currCollection === 'all') return collectionsList

    const payload = {
      [currCollection]: collectionsList[currCollection],
    }

    if (!currentCategory) return payload

    return filterCategories(payload, currentCategory)
  },
)

export const selectCollections = createDeepEqualSelector(
  [selectCollectionsList, selectSearchTerm],
  (collections, term) => {
    if (!collections) return null
    if (!term) return collections

    const clonedCollections = JSON.parse(JSON.stringify(collections))

    Object.keys(clonedCollections).forEach((collection) => {
      const { features } = clonedCollections[collection]

      clonedCollections[collection].features = filterByName(features, term)
    })

    return clonedCollections
  },
)

// This selector can be avoid in the future if we add their collection name in each feature
export const selectFlatenFeatures = createDeepEqualSelector(
  [selectCollections],
  (collections) => {
    if (!collections) return null

    return Object.keys(collections).reduce((acc, cur) => {
      return acc.concat(
        collections[cur].features.map((feature) => ({ ...feature, collection: cur })),
      )
    }, [])
  },
)
