import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { selectPanelIsVisible, selectListIsVisible } from 'state/Ui/selectors'
import * as collectionsSelectors from 'state/Collections/selectors'
import { setProjectInfo, setCardContent } from 'state/Info/actions'
import { setCollectionsSearchTerm, setClickedFeature } from 'state/Collections/actions'
import { setListVisibility } from 'state/Ui/actions'
import { useDispatch, useSelector } from 'react-redux'

import { LogoIcon } from 'assets/icons'
import { SearchBar } from 'components/layouts'
import { CollectionSelector, List, PanelHandler, Card, ProjectInfo } from './components'

import { PanelHeaderStyled } from './components/Header/Header.styled'
import { PanelContainerStyled, PanelInnerStyled } from './Panel.styled'

export const Panel = ({ setView, resetViewport }) => {
  const panelIsVisible = useSelector(selectPanelIsVisible)
  const listIsVisible = useSelector(selectListIsVisible)

  const searchTerm = useSelector(collectionsSelectors.selectSearchTerm)
  const clickedFeature = useSelector(collectionsSelectors.selectClickedFeature)
  const dispatch = useDispatch()
  const setSearch = (value) => dispatch(setCollectionsSearchTerm(value))

  const handleListVisibility = () => {
    dispatch(setListVisibility(false))
    dispatch(setClickedFeature(null))
    dispatch(setProjectInfo(null))
    dispatch(setCardContent(null))
  }

  useEffect(() => {
    return () => {
      dispatch(setCollectionsSearchTerm(''))
    }
  }, [])

  return (
    <PanelContainerStyled {...{ panelIsVisible, clickedFeature, listIsVisible }}>
      <PanelInnerStyled>
        <PanelHeaderStyled>
          <LogoIcon onClick={handleListVisibility} />
          <PanelHandler />
        </PanelHeaderStyled>
        <CollectionSelector {...{ setView }} />
        {listIsVisible && (
          <>
            <SearchBar searchTerm={searchTerm} onSetSearchTerm={setSearch} />
            <List {...{ setView }} />
          </>
        )}
        {!listIsVisible && <ProjectInfo />}
      </PanelInnerStyled>
      {}
      {clickedFeature && <Card {...{ resetViewport, clickedFeature }} />}
    </PanelContainerStyled>
  )
}

Panel.propTypes = {
  setView: PropTypes.func.isRequired,
  resetViewport: PropTypes.func.isRequired,
}

export default React.memo(Panel)
