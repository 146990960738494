import { FlyToInterpolator, WebMercatorViewport } from 'react-map-gl'
import Leaflet from 'leaflet'
import * as d3 from 'd3-ease'
import theme from 'styles/theme'
import { getKey } from 'utils/key-generator'
import { COLLECTION_NAMES_LIST } from 'constants/index'
import { useCenter } from 'hooks'
import { getCollectionFromID } from 'utils'

const getMapColorsStops = (categoriesConfig) => {
  if (!categoriesConfig.length) {
    return [['default', theme.colors.darkBlue]]
  }

  return categoriesConfig.map(({ name, mapColor, accentColor } = {}) => {
    const color = mapColor
      ? theme.colors[mapColor?.color]
      : theme.colors[accentColor.color]

    return [name, color]
  })
}

const setDataLayer = (
  collection,
  options = { usePolygon: false, usePath: false, useLine: false },
  categoriesConfig,
) => {
  const { usePolygon, usePath, useLine } = options
  let type = 'circle'
  if (usePolygon) {
    type = 'fill'
  } else if (useLine) {
    type = 'line'
  }

  const drawPath = usePath ? { 'fill-color': theme.colors.transparent } : null
  const drawLine = useLine
    ? { 'line-color': theme.colors.darkBlue, 'line-width': 1.5 }
    : null

  return {
    id: `${collection.name}##${getKey()}`,
    type,
    paint: {
      [`${type}-color`]: {
        type: 'categorical',
        property: 'category',
        stops: getMapColorsStops(categoriesConfig),
      },
      ...drawPath,
      ...drawLine,
      [`${type}-opacity`]: 0.8,
    },
  }
}

const getClickedFeature = (event) => {
  const { features } = event || {}

  const clickedFeature =
    features &&
    features.find((feature) => {
      const name = getCollectionFromID(feature)
      return COLLECTION_NAMES_LIST.includes(name)
    })

  return clickedFeature || false
}

const flyTo = (feature, zoom, position) => {
  const [long, lat] = useCenter(feature) || position.slice().reverse()

  return {
    longitude: long,
    latitude: lat,
    zoom,
    transitionDuration: 800,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: d3.easeCubic,
  }
}

const getFeaturesBounds = (collections = null) => {
  if (!collections) return null

  const consolidatedCollections = Object.values(collections).reduce(
    (acc, current) => [...current.features, ...acc],
    [],
  )

  return Leaflet.geoJson({
    name: 'consolidatedTemporaryCollection',
    type: 'FeatureCollection',
    features: [...consolidatedCollections],
  }).getBounds()
}

const getMapPaddings = () => {
  const basePadding = window.innerWidth > 768 ? 20 : 10
  return {
    top: basePadding,
    right: basePadding,
    bottom: basePadding,
    left:
      window.innerWidth > 768
        ? theme.components.panelWidthAsNumber + theme.components.panelLeftPosAsNumber
        : basePadding,
  }
}

const getMapViewport = (bounds) => {
  if (!bounds || !('_southWest' in bounds) || !('_northEast' in bounds)) return null

  const reorderedBoundsAsArray = [
    [bounds._southWest.lng, bounds._southWest.lat],
    [bounds._northEast.lng, bounds._northEast.lat],
  ]

  const viewport = new WebMercatorViewport({
    width: window.innerWidth,
    height: window.innerHeight,
  }).fitBounds(reorderedBoundsAsArray, {
    padding: getMapPaddings(),
  })

  const { longitude, latitude, zoom } = viewport
  return { longitude, latitude, zoom }
}

export { setDataLayer, getClickedFeature, flyTo, getFeaturesBounds, getMapViewport }
