import React from 'react'
import { ThemeProvider } from 'styled-components'
import ErrorBoundary from 'components/pages/ErrorBoundary'
import { MapboxMap } from 'components/layouts'
import { MainContainerStyled } from 'styles/components'
import { GlobalStyles } from 'styles'
import theme from 'styles/theme'

const App = () => (
  <ErrorBoundary>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <MainContainerStyled>
        <MapboxMap />
      </MainContainerStyled>
    </ThemeProvider>
  </ErrorBoundary>
)

export default App;
