import React from 'react'
import PropTypes from 'prop-types'

import { ResponsiveIframe } from './VimeoPlayer.styled'

const VimeoPlayer = ({ videoId }) => {
  if (!videoId) return null

  return (
    <ResponsiveIframe
      title="vimeo-player"
      src={`https://player.vimeo.com/video/${videoId}`}
      frameBorder="0"
      allowFullScreen
    />
  )
}

export default VimeoPlayer

VimeoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
}
