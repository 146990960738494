import React from 'react'
import theme from 'styles/theme'
import {
  ComercialIcon,
  ComercialIconSelected,
  LugaresIcon,
  LugaresIconSelected,
  GenteIcon,
  GenteIconSelected,
} from 'assets/icons'
import { CircleFormStyled } from 'styles/components'

export const getCategoryAccentColor = (category, categoriesConfig) => {
  const { accentColor } = categoriesConfig.find(({ name }) => name === category) || {}

  return accentColor?.color || theme.colors.darkBlue
}

export const getMenuButtonAccentColor = (collection) => {
  const options = {
    lugares: {
      background: theme.colors.brand2Light,
      text: theme.colors.brand2,
    },
    gente: {
      background: theme.colors.fluorGreenLight,
      text: theme.colors.fluorGreen,
    },
    comercial: {
      background: theme.colors.pinkLight,
      text: theme.colors.pink,
    },
    default: {
      background: theme.colors.brand1Light,
      text: theme.colors.brand1,
    },
  }

  return options[collection] || options.default
}

export const getCategoryComponent = ({
  category,
  categoriesConfig,
  selected = false,
}) => {
  const options = {
    'Pequeño comercio': () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    'Juegos de azar y apuestas': () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    Propuesta: () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    Memoria: () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    Tradicional: () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    Aliado: () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    Orange: () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    'Lugar Potencial': () => (
      <CircleFormStyled
        color={selected ? 'white' : 'transparent'}
        borderColor={selected ? 'white' : theme.colors.darkBlue}
        outlined
      />
    ),
    'Patrimonio Industrial': () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
    default: () => (
      <CircleFormStyled
        color={selected ? 'white' : getCategoryAccentColor(category, categoriesConfig)}
      />
    ),
  }

  return (options[category] || options.default)()
}

export const getCollectionIcon = ({ collection, selected = false }) => {
  const options = {
    comercial: selected ? <ComercialIconSelected /> : <ComercialIcon />,
    lugares: selected ? <LugaresIconSelected /> : <LugaresIcon />,
    gente: selected ? <GenteIconSelected /> : <GenteIcon />,
    default: null,
  }

  return options[collection] || options.default
}

export const getCollectionFromID = (feature) => feature.layer.id.split('##')[0]

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)
