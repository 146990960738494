import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getKey } from 'utils/key-generator'
import { ZOOM_OUT } from 'constants/index'
import { getCategoryComponent, getCollectionIcon, getMenuButtonAccentColor } from 'utils'

import {
  selectCurrentCollection,
  selectCurrentCategory,
  selectCategories,
  selectCategoriesConfig,
  selectDistricts,
  selectCurrentDistrict,
} from 'state/Collections/selectors'
import {
  getDistrict,
  setClickedFeature,
  setCollectionsSearchTerm,
  setCurrentCollectionAndCategory,
} from 'state/Collections/actions'
import { setListVisibility } from 'state/Ui/actions'
import { setCardContent } from 'state/Info/actions'

import {
  CollectionActionsStyled,
  CategoriesGroupStyled,
  CollectionSelectorStyled,
  CollectionMenuButton,
  CategoryMenuButton,
  DistrictMenuButton,
  DistrictSelectorStyled,
} from './CollectionSelector.styled'

const SHORTENED_CATEGORY_NAMES = {
  'Lugar Potencial': 'Potencial',
  'Patrimonio Industrial': 'Industrial',
  'Juegos de azar y apuestas': 'Juegos, azar, apuestas',
  Tradicional: 'Tradicionales',
  Aliado: 'Aliados',
}

const SORTED_COLLECTIONS = {
  gente: 'gente',
  lugares: 'lugares',
  comercial: 'comercial',
}

const CollectionSelector = ({ setView }) => {
  const dispatch = useDispatch()
  const currentCategory = useSelector(selectCurrentCategory)
  const currentCollection = useSelector(selectCurrentCollection)
  const categories = useSelector(selectCategories)
  const categoriesConfig = useSelector(selectCategoriesConfig)
  const districts = useSelector(selectDistricts)
  const currDistricts = useSelector(selectCurrentDistrict)

  const isCategorySelected = (category) => currentCategory === category

  const isCollectionsSelected = (collection) => {
    return !currentCategory && collection === currentCollection
  }

  const onSelectMenuItems = (collection, category = null) => {
    dispatch(setClickedFeature(null))
    dispatch(setListVisibility(true))
    dispatch(setCollectionsSearchTerm(''))
    setView(false, ZOOM_OUT)
    dispatch(setCurrentCollectionAndCategory({ collection, category }))
  }

  const handleOnClickDistrict = (district) => {
    dispatch(setCardContent(null))
    dispatch(setClickedFeature(null))
    setCurrentCollectionAndCategory({ collection: 'all', category: null })
    dispatch(getDistrict(district))
  }

  // eslint-disable-next-line react/prop-types
  const CollectionBlock = ({ children }) => (
    <CollectionSelectorStyled>
      <DistrictSelectorStyled>
        {districts.map((district) => (
          <DistrictMenuButton
            selected={currDistricts.id === district.id}
            onClick={() => handleOnClickDistrict(district)}
            key={getKey()}
          >
            {district.name}
          </DistrictMenuButton>
        ))}
      </DistrictSelectorStyled>
      {categories &&
        Object.keys(SORTED_COLLECTIONS)?.map((collection) => {
          return (
            <CollectionActionsStyled key={getKey()}>
              <CollectionMenuButton
                selected={isCollectionsSelected(collection)}
                onClick={() => onSelectMenuItems(collection.toLowerCase())}
                key={getKey()}
                colorConfig={getMenuButtonAccentColor(collection)}
              >
                {getCollectionIcon({
                  collection,
                  selected: isCollectionsSelected(collection),
                })}
                {collection}
              </CollectionMenuButton>
              <CategoriesGroupStyled>
                {React.cloneElement(children, { collection })}
              </CategoriesGroupStyled>
            </CollectionActionsStyled>
          )
        })}
    </CollectionSelectorStyled>
  )

  const CategoryBlock = ({ collection }) => {
    return Object.keys(categories[collection])?.map((key, idx) => {
      const category = categories[collection][key]
      const isLastChild = Object.keys(categories[collection]).length === idx + 1
      return (
        <CategoryMenuButton
          key={getKey()}
          onClick={() => onSelectMenuItems(collection, category)}
          selected={isCategorySelected(category)}
          isLastChild={isLastChild}
          colorConfig={getMenuButtonAccentColor(collection)}
        >
          {getCategoryComponent({
            category,
            categoriesConfig,
            selected: isCategorySelected(category),
          })}
          {SHORTENED_CATEGORY_NAMES[category] || category}
        </CategoryMenuButton>
      )
    })
  }

  return (
    <CollectionBlock>
      <CategoryBlock />
    </CollectionBlock>
  )
}

CollectionSelector.propTypes = {
  setView: PropTypes.func.isRequired,
}

export default CollectionSelector
