import styled from 'styled-components'
import theme from 'styles/theme'

export const ImageLightBoxStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgb(255 255 255 / 65%);
  z-index: 9;

  img {
    max-height: 95vh;
    max-width: 95vw;
    width: 960px;
    border-radius: 6px;
    object-fit: cover;
  }
`

export const ImageLightBoxInnerStyled = styled.section`
  margin: 0 auto;
  position: relative;
  background-color: ${({ hasError }) => (hasError ? 'white' : null)};
  padding: ${({ hasError }) => (hasError ? '100px' : '0')};
  border-radius: ${({ hasError }) => (hasError ? '2px' : '0')};
`

export const ImageLightBoxCloseButtonStyled = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  background-color: ${theme.colors.gray10};
  transform: scale(2);
  line-height: 0.5;
  padding: 4px;
  border-radius: 2px;
  transition: background-color 0.2s ease-out;

  g {
    stroke: ${theme.colors.brand2};
    transition: stroke 0.2s ease-out;
  }
  &:hover {
    background-color: ${theme.colors.orange};

    g {
      stroke: ${theme.colors.white};
    }
  }
`

export const ImageLightboxErrorButtonStyled = styled.button`
  background-color: ${theme.colors.brand2};
  color: white;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 16px;
  font-family: 'Roboto';
  font-size: 1rem;
`
