import * as types from './types'
import initialState from './initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.COLLECTION_SET_DISTRICTS:
      return {
        ...state,
        districts: action.payload.districts,
      }
    case types.COLLECTION_SET_DISTRICT:
      return {
        ...state,
        currentDistrict: action.payload.district,
      }
    case types.COLLECTION_SET_ALL_COLLECTIONS:
      return {
        ...state,
        collectionsList: action.payload.collections,
      }
    case types.COLLECTION_SET_COLLECTIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case types.COLLECTION_SET_COLLECTIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case types.COLLECTION_SET_COLLECTIONS_NAMES:
      return {
        ...state,
        collectionsNames: action.payload,
      }
    case types.COLLECTION_SET_COLLECTIONS_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      }
    case types.COLLECTION_SET_CLICKED_FEATURE:
      return {
        ...state,
        clickedFeature: action.payload,
      }
    case types.COLLECTION_SET_CURRENT_COLLECTION_AND_CATEGORY:
      return {
        ...state,
        currentCollection: action.payload.collection,
        currentCategory: action.payload.category,
      }
    case types.COLLECTION_SET_TOTAL_FEATURES:
      return {
        ...state,
        totalFeatures: action.payload,
      }
    case types.COLLECTION_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }
    case types.COLLECTION_SET_CATEGORIES_CONFIG:
      return {
        ...state,
        categoriesConfig: action.payload,
      }
    default:
      return state
  }
}
