import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const BreadCrumbStyled = styled.div`
  margin-bottom: ${getRem(20)};
  span {
    font-size: ${getRem(12)};
    color: ${({ theme }) => theme.colors.grays30};
  }
`
export const CategoryStyled = styled.span`
  color: ${({ color, theme }) => theme.colors[color]};
`

export const BreadCrumbButtonStyled = styled.button`
  font-family: 'Roboto';
  transition: color 0.1s ease-out;
  font-size: ${getRem(12)};
  color: ${({ theme }) => theme.colors.grays30};
  cursor: ${({ unaccent }) => (unaccent ? 'text' : 'pointer')};

  &:hover {
    color: ${({ theme, unaccent }) =>
      unaccent ? theme.colors.grays30 : theme.colors.orange};
  }
`
