import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectCurrentCollection,
  selectCurrentCategory,
  selectCategoriesConfig,
  selectCurrentDistrict,
} from 'state/Collections/selectors'
import { setCurrentCollectionAndCategory } from 'state/Collections/actions'

import { capitalize, getCategoryAccentColor } from 'utils'
import {
  BreadCrumbStyled,
  BreadCrumbButtonStyled,
  CategoryStyled,
} from './BreadCrumb.styled'

const BreadCrumb = ({ length }) => {
  const dispatch = useDispatch()
  const district = useSelector(selectCurrentDistrict)
  const collection = useSelector(selectCurrentCollection)
  const category = useSelector(selectCurrentCategory)
  const categoriesConfig = useSelector(selectCategoriesConfig)

  const accentColor = getCategoryAccentColor(category, categoriesConfig)

  const onCollectionClick = () => {
    dispatch(setCurrentCollectionAndCategory({ collection, category: null }))
  }

  const resetSelected = () => {
    dispatch(setCurrentCollectionAndCategory({ collection: 'all', category: null }))
  }

  const home = (
    <>
      <BreadCrumbButtonStyled type="button" onClick={resetSelected}>
        Inicio
      </BreadCrumbButtonStyled>
      <span> / </span>
    </>
  )

  const curDistrict = (
    <BreadCrumbButtonStyled type="button" onClick={resetSelected}>
      {capitalize(district.name)}
    </BreadCrumbButtonStyled>
  )

  const curCollection = (
    <>
      <span>{' / '}</span>
      <BreadCrumbButtonStyled
        type="button"
        onClick={onCollectionClick}
        unaccent={!category}
      >
        {collection === 'all' ? 'Todos' : capitalize(collection)}
      </BreadCrumbButtonStyled>
      <span> </span>
    </>
  )

  const currCategory = (
    <>
      <span>{' / '}</span>
      <BreadCrumbButtonStyled type="button" unaccent>
        <CategoryStyled color={accentColor}>{`${category} (${length})`}</CategoryStyled>
      </BreadCrumbButtonStyled>
    </>
  )

  const currLength = <CategoryStyled color={accentColor}>{`(${length})`}</CategoryStyled>

  return (
    <BreadCrumbStyled>
      {home}
      {curDistrict}
      {curCollection}
      {category ? currCategory : currLength}
    </BreadCrumbStyled>
  )
}

BreadCrumb.propTypes = {
  length: PropTypes.number.isRequired,
}

export default BreadCrumb
