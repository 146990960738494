import React from 'react'
import PropTypes from 'prop-types'

import { ImageLightboxErrorButtonStyled } from '../ImageLightBox.styled'

const ImageLightboxError = ({ onClick }) => {
  return (
    <section>
      <p>Ops! Esta imagen no se ha podido cargar 😕</p>
      {onClick && (
        <ImageLightboxErrorButtonStyled onClick={onClick} type="button">
          Cerrar
        </ImageLightboxErrorButtonStyled>
      )}
    </section>
  )
}

ImageLightboxError.defaultProps = {
  onClick: null,
}

ImageLightboxError.propTypes = {
  onClick: PropTypes.func,
}

export default ImageLightboxError
