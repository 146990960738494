import React, { useState, useEffect } from 'react'
import MapGL, { NavigationControl } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'

import { getCollectionFromID } from 'utils'
import {
  getAllCollections,
  setClickedFeature,
  setCurrentCollectionAndCategory,
} from 'state/Collections/actions'
import { setListVisibility } from 'state/Ui/actions'
import { selectCurrentDistrict } from 'state/Collections/selectors'
import { ZOOM_IN } from 'constants/index'
import { Panel } from 'components/layouts'
import { Loader } from 'components/common'

import { GeoJSONLayers, Tooltip } from './components'
import { NavigationControlStyled } from './MapboxMap.styled'
import { getClickedFeature, flyTo } from './MapboxMap.helpers'
import { useBoundedViewport } from './MapboxMap.hooks'

const MapboxMap = () => {
  const dispatch = useDispatch()
  const { position } = useSelector(selectCurrentDistrict)

  const [mapIsLoading, setMapIsLoading] = useState(true)

  const { resetViewport, viewport, setViewport } = useBoundedViewport({ position })

  useEffect(() => {
    dispatch(getAllCollections())
  }, [])

  const setView = (feature, zoom) => {
    const newViewport = {
      ...viewport,
      ...flyTo(feature, zoom, position),
    }

    setViewport(newViewport)
  }

  const handleClick = (event) => {
    const feature = getClickedFeature(event)
    if (!feature) return

    const collection = getCollectionFromID(feature)
    const { category } = feature.properties

    dispatch(setCurrentCollectionAndCategory({ collection, category }))
    dispatch(setClickedFeature(feature))
    dispatch(setListVisibility(true))
    setView(feature, ZOOM_IN)
  }

  const handleTooltipClose = () => {
    dispatch(setClickedFeature(null))
    resetViewport()
  }

  const handleLoad = () => setMapIsLoading(false)

  return (
    <>
      <Loader show={mapIsLoading} />
      <MapGL
        {...viewport}
        width="100vw"
        height="100vh"
        mapStyle="mapbox://styles/mapbox/light-v10"
        onViewportChange={setViewport}
        onLoad={handleLoad}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onClick={handleClick}
        attributionControl={false}
      >
        <GeoJSONLayers />
        <Tooltip {...{ handleTooltipClose, setView }} />
        <NavigationControlStyled>
          <NavigationControl showCompass={false} />
        </NavigationControlStyled>
      </MapGL>
      <Panel {...{ setView, resetViewport }} />
    </>
  )
}

export default MapboxMap
